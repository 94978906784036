<template>
  <van-form
    :class="{
      'van-form__text-color': !isAdd
    }"
    :show-error-message="false"
    label-width="22vw"
    @submit="onSubmit"
    @failed="onFailed"
  >
    <van-field
      v-if="isAdd"
      :required="isAdd"
      :rules="[{ required: isAdd, message: '提交意见' }]"
      label="提交意见"
      name="passStatus"
    >
      <template #input>
        <van-radio-group
          v-model="approvalInfo.passStatus"
          :disabled="!isAdd"
          direction="horizontal"
        >
          <van-radio
            v-for="item in passStatusData"
            :key="item.value"
            :name="item.value"
            >{{ item.label }}</van-radio
          >
        </van-radio-group>
      </template>
    </van-field>
    <template v-if="+approvalInfo.passStatus === 1">
      <van-field
        :rules="[{ required: isAdd, message: '请填写改善级别' }]"
        :value="approvalInfo.troubleLevelName"
        :clickable="isAdd"
        label="改善级别"
        name="troubleLevel"
        :placeholder="placeholder('choose')"
        :readonly="!isAdd"
        :required="isAdd"
        @click="handlerTypesClick($event, 'troubleLevel')"
        @focus="preventKeyboard"
      />
      <van-field
        :rules="[{ required: isAdd, message: '请选择所属部门' }]"
        :value="approvalInfo.departmentName"
        :clickable="isAdd"
        label="所属部门"
        name="department"
        :placeholder="placeholder('choose')"
        :readonly="!isAdd"
        :required="isAdd"
        @click="handlerTypesClick($event, 'department')"
      />
      <van-field
        v-model="approvalInfo.receiverName"
        :rules="[{ message: '请填写部位名称' }]"
        label="整改责任人"
        name="name"
        :placeholder="placeholder()"
        readonly
        @click="onPersonClick($event, 'leader')"
      />
      <van-field
        v-model="approvalInfo.checkerName"
        :rules="[{ message: '请填写部位名称' }]"
        label="整改验收人"
        name="name"
        :placeholder="placeholder()"
        readonly
        @click="onPersonClick($event, 'checker')"
      />
      <van-field
        :disabled="!isAdd"
        :required="isAdd"
        :rules="[{ required: true, message: '请选择点击选择时间' }]"
        :value="approvalInfo.planTime"
        :clickable="isAdd"
        label="限期整改"
        name="planTime"
        :placeholder="placeholder('time')"
        readonly
        @click="handlerTimeClick"
      />
      <van-field
        v-model="approvalInfo.suggest"
        autosize
        :readonly="!isAdd"
        label="控制措施"
        maxlength="500"
        name="suggest"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('suggest')"
      />
      <van-field
        v-model="approvalInfo.measure"
        autosize
        :readonly="!isAdd"
        label="整改措施"
        maxlength="500"
        name="measure"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('measure')"
      />
    </template>
    <template v-else>
      <van-field
        v-model="approvalInfo.content"
        :disabled="!isAdd"
        :show-word-limit="isAdd"
        autosize
        :label="+approvalInfo.passStatus === 2 ? `退回原因` : `备注`"
        maxlength="500"
        :placeholder="placeholder()"
        rows="2"
        type="textarea"
        @input="handleInput('content')"
      />
    </template>
    <div v-if="isAdd" class="details-page__footer-button-wrapper--fixed">
      <van-button
        :disabled="loading.status"
        :loading="loading.status"
        :loading-text="loading.text"
        block
        native-type="submit"
        round
        type="info"
        >提交</van-button
      >
    </div>
    <!-- 组件 -->
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '70vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
      @reset="onPopupReset"
    />
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="initTime"
        :min-date="minDate"
        type="date"
        @cancel="showTimePicker = false"
        @confirm="onDatetimeConfirm"
      />
    </van-popup>
    <select-person
      :title="selectPersonTitle"
      :visible="selectPersonVisible"
      :choose-value="choosePersonSelect"
      position="bottom"
      @close="onPersonClose"
      @confirm="onPersonConfirm"
    />
  </van-form>
</template>

<script>
import { passStatusData } from "./utils/constant";
import { filterEmoji, parseTime, operateMessage } from "@/utils";
import { loading } from "@/utils/constant";
import getSelectMixin from "@/views/improveTrouble/trouble/mixin/getSelectMixin";
import {
  approveTrouble,
  refuseApproves,
  getApprovePersonConf,
  getTroubleApproveInfoById,
  notTrouble,
  getTroubleInfoById
} from "@/api/improve/improve";
import { Toast } from "vant";
import SelectPerson from "@/components/SelectPerson";
import SelectPopup from "@/components/SelectPopup";
import { mapState } from "vuex";

export default {
  components: { SelectPopup, SelectPerson },
  mixins: [getSelectMixin],
  props: ["id"],
  inject: {
    detailsComponents: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      passStatusData,
      isAdd: false,
      minDate: new Date(),
      initTime: new Date(),
      loading: { ...loading },
      approvalInfo: {
        troubleId: "",
        passStatus: "1",
        content: "",
        troubleLevelName: "",
        troubleLevel: "",
        planTime: "",
        measure: "",
        suggest: "",
        // 整改责任人
        leader: [],
        receiverName: "",
        // 整改验收人
        checker: [],
        checkerName: "",
        department: "",
        departmentName: ""
      },
      popupTypesConf: {
        troubleLevel: {
          title: "改善级别",
          keyName: "troubleLevelName",
          typesKey: "levelTypes"
        },
        department: {
          title: "所属部门",
          keyName: "departmentName",
          typesKey: "departmentSelect"
        }
      },
      personConf: {
        checker: {
          select: [],
          title: "整改验收人",
          typeNameKey: "checkerName",
          selectTypes: "checkerSelect"
        },
        leader: {
          select: [],
          title: "整改责任人",
          typeNameKey: "receiverName",
          selectTypes: "leaderSelect"
        }
      },
      popupType: "",
      popupTypeName: "",
      popupValue: "",
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      showTimePicker: false,
      selectPersonVisible: false,
      selectPersonTitle: "",
      choosePersonSelect: [],
      leaderSelect: [],
      checkerSelect: [],
      departmentSelect: [],
      currentPersonType: ""
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    troubleInfo() {
      return this.detailsComponents.troubleInfo;
    }
  },
  watch: {
    "approvalInfo.passStatus"(val) {
      if (+val === 0) {
        Object.keys(this.approvalInfo).forEach(key => {
          if (
            key !== "troubleId" &&
            key !== "passStatus" &&
            key !== "content" &&
            key !== "leader" &&
            key !== "receiverName" &&
            key !== "checker" &&
            key !== "checkerName" &&
            key !== "troubleLevelName" &&
            key !== "troubleLevel"
          ) {
            this.approvalInfo[key] = "";
          }
        });
      } else if (+val === 1) {
        this.approvalInfo.content = "";
      }
    }
  },
  async created() {
    this.isAdd = JSON.parse(this.$route.query.add);
    if (!this.isAdd) {
      this.getInfo();
    } else {
      // this.getApprovePerson(this.id);
      await this.setInfo();
    }
  },
  methods: {
    async setInfo() {
      let troubleInfo;
      if (!this.troubleInfo?.id) {
        troubleInfo = await getTroubleInfoById(this.id);
      } else {
        troubleInfo = this.troubleInfo;
      }
      const {
        troubleLevel,
        troubleLevelName,
        measure,
        suggest,
        gist,
        department,
        departmentName
      } = troubleInfo;
      this.approvalInfo.troubleLevel = troubleLevel;
      this.approvalInfo.troubleLevelName = troubleLevelName;
      this.approvalInfo.measure = measure;
      this.approvalInfo.suggest = suggest;
      this.approvalInfo.gist = gist;
      this.approvalInfo.department = department;
      this.approvalInfo.departmentName = departmentName;
      this.getApprovePerson(this.id, department, troubleLevel);
    },
    async getInfo() {
      try {
        this.approvalInfo = await getTroubleApproveInfoById(this.id);
        this.setInfo();
      } catch (e) {
        console.log(e);
      }
    },
    async getApprovePerson(id, department = "", troubleLevel = "") {
      try {
        const config = await getApprovePersonConf(
          id,
          department,
          troubleLevel,
          this.userInfo.orgCode
        );
        if (!config) return;
        const {
          checkers,
          receivers,
          receiverSelect,
          checkerSelect,
          departmentsSelect
        } = config;
        this.approvalInfo.checker = checkers;
        this.approvalInfo.checkerName = checkerSelect.map(x => x.label).join();
        this.approvalInfo.leader = receivers;
        this.approvalInfo.receiverName = receiverSelect
          .map(x => x.label)
          .join();
        this.personConf.leader.select = this.leaderSelect = receiverSelect;
        this.personConf.checker.select = this.checkerSelect = checkerSelect;
        this.departmentSelect = departmentsSelect;
      } catch (e) {
        console.log(e);
      }
    },
    onPersonClick(ev, type) {
      if (!this.isAdd) return;
      ev.preventDefault();
      const config = this.personConf;
      this.selectPersonTitle = config[type].title;
      this.choosePersonSelect = [...config[type].select];
      this.selectPersonVisible = true;
      this.currentPersonType = type;
    },
    onPersonConfirm(ids, list) {
      const currentPersonType = this.currentPersonType;
      const config = this.personConf[currentPersonType];
      this.approvalInfo[currentPersonType] = ids;
      this.approvalInfo[config.typeNameKey] = list.map(x => x.label).join();
      this.personConf[currentPersonType].select = this[config.selectTypes] = [
        ...list
      ];
    },
    onPersonClose() {
      this.selectPersonVisible = false;
    },
    placeholder(type = "content") {
      let content = "请输入内容";
      if (type === "time") {
        content = "点击选择时间";
      }
      if (type === "choose") {
        content = "请选择";
      }
      return this.isAdd ? content : "--";
    },
    handleInput(key) {
      this.approvalInfo[key] = filterEmoji(this.approvalInfo[key]);
    },
    preventKeyboard() {
      document.activeElement.blur();
    },
    handlerTypesClick(ev, key) {
      ev.preventDefault();
      if (!this.isAdd) return;
      // 如果之前被选中了，那就直接传递过去
      this.popupValue = this.approvalInfo[key];

      const popupTypesConf = this.popupTypesConf;
      this.popupVisible = true;
      this.popupTitle = popupTypesConf[key].title;

      this.popupData = [...this[popupTypesConf[key].typesKey]];
      this.popupType = key;
      this.popupTypeName = popupTypesConf[key].keyName;
    },
    onPopupConfirm(row) {
      this.approvalInfo[this.popupType] = row.value;
      this.approvalInfo[this.popupTypeName] = row.label;
      if (this.popupType === "troubleLevel") {
        // 更新部门
        this.approvalInfo.department = "";
        this.approvalInfo.departmentName = "";
        this.getApprovePerson(this.id, "", row.value);
      } else if (this.popupType === "department") {
        // 更新人员
        this.approvalInfo.checker = [];
        this.approvalInfo.leader = [];
        this.approvalInfo.checkerName = "";
        this.approvalInfo.receiverName = "";
        this.personConf.leader.select = [];
        this.personConf.checker.select = [];
        this.getApprovePerson(
          this.id,
          row.value,
          this.approvalInfo.troubleLevel
        );
      }
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupReset() {
      this.approvalInfo[this.popupType] = "";
      this.popupValue = "";
    },
    handlerTimeClick() {
      if (!this.isAdd) return;
      this.approvalInfo.planTime &&
        (this.initTime = new Date(this.approvalInfo.planTime));
      this.showTimePicker = true;
    },
    onDatetimeConfirm(time) {
      this.approvalInfo.planTime = parseTime(time, "{y}-{m}-{d}");
      this.showTimePicker = false;
    },
    async onRefuse() {
      try {
        const ret = await refuseApproves(this.approvalInfo);
        operateMessage(!!ret, "审批拒绝");
        if (ret) this.$router.back();
        console.log("onRefuse -> r", ret);
      } catch (e) {
        console.log("onRefuse -> e", e);
      }
    },
    async onPass() {
      try {
        const { leader, checker } = this.approvalInfo;
        if (!leader.length || !checker.length) {
          return Toast("请选择整改责任人或整改验收人");
        }
        const ret = await approveTrouble(this.approvalInfo);
        operateMessage(!!ret, "审批通过");
        if (ret) this.$router.back();
      } catch (e) {
        console.log("onPass -> e", e);
      }
    },
    onFailed(errorInfo) {
      console.log(errorInfo, "errorInfo");
    },
    async notTrouble() {
      try {
        const r = await notTrouble(this.approvalInfo);
        operateMessage(!!r, "不是改善");
        if (r) {
          this.$router.back();
        }
      } catch (e) {
        console.log(e);
      }
    },
    onSubmit() {
      this.approvalInfo.troubleId = this.id;
      const passStatus = +this.approvalInfo.passStatus;
      if (passStatus === 2) {
        this.onRefuse();
      } else if (passStatus === 1) {
        this.onPass();
      } else {
        this.notTrouble();
      }
    }
  }
};
</script>

<style></style>
